@import '../../shared';

.product-item {
  width: 50%;
  font: 400 14px $font;
  border: 1px solid #DCDCDC;
  border-top: none;
  border-left: none;
  
  &-link {
    position: relative;
    display: block;
    padding: 10% 20px 100%;
    height: 0;
    background-color: #fff;
  }

  &-name {
    width: 100%;
    text-align: center;
  }

  &-img {
    position: relative;
    display: block;
    margin: 0 auto;
    width: 70%;
    height: 0;
    padding-bottom: 70%;

    img {
      position: absolute;
      top:50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
    }
  }
}