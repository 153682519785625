.share {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  &-text {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    transform: translate(-50%, -50%);
    text-align: center;

    .btn {
      margin-top: 30px;
    }
  }

  &.black {
    background-color: #272727;
    color: #fff;
    transition: all .2s;
  }

  .logo {
    position: absolute;
    top:20px;
    right: 20px;
    width: 30px;
  }
}

.wishlist-pdf {
  position: fixed;
  left: -9999px;
  top: 0;
  // opacity: 0;
  // visibility: hidden;
  background: #F9F9F9;
  width: 595px;
  min-height: 600px; 
  padding: 55px 50px 0px 55px;

  .pdf-title {
    position: relative;
    font-size: 12px;
    padding: 0 0 25px;

    p  > span {
      display: block;
    }
    img {
      position: absolute;
      right: 0;
      top: 0;
      width: 50px;
    }
  }

  .pdf-list {
    background: #fff;
    padding: 0 40px;
    margin-bottom: 100px;

    .wishlist-product {
      padding-bottom: 20px;
    }

    .product-image {
      width: 17%;
      margin-right: 10px;
    }
    .product-name {
      font-size: 12px;
    }
    .product-options {
      font-size: 10px;
      padding-top: 5px;
      span {
        display: block;
      }
    }
  }

  .wishlist-note {
    padding: 25px 0 31px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 8px;
    p {
      padding-bottom: 5px;
      &:last-child {
        padding-bottom: 0;
      }
    }
    b {
      display: block;
      font-weight: 700;
      font-size: 10px;
      padding-bottom: 10px;
    }
    strong {
      font-weight: 700;
      border-bottom: 1px solid #27272785;
    }
  }

  .pdf-bottom {
    position: absolute;
    bottom: 30px;
    left: 55px;
    right: 55px;
    display: flex;
    justify-content: space-between;
    font-size: 8px;
    // height: 70px;
    b {
      font-weight: 700;
    }
    img {
      display: inline-block;
      vertical-align: middle;
      width: 10px;
      margin-top: -1px;
      margin-right: 5px;
    }
  }
  .date {
    text-transform: capitalize;
  }
}