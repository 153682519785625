.add-button-wrapper {
  position: fixed;
  z-index: 4;
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: 67px;
  padding: 8px 24px;
  background-color: #fff;
  box-shadow: 0 0 12px 0 rgba(41,41,41,0.05);

  .add-amount {
    position: absolute;
    top:calc(50% - 10px);
    right: 24px;
    background-color: #fff;
    padding: 0 2px;
    width: 20px;
    font-size: 10px;
    line-height: 20px;
    border-radius: 2px;
  }
}