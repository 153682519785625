@import '../../_shared.scss';
.modal {
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 30px 25px;

  color: #A1A1A1;
  line-height: 1.6;

  &-content {
    width: 100%;
    background: #fff;
    padding: 30px 20px 20px;
    box-shadow: 0 -1px 13px 0 rgba(0,0,0,0.11);
  }

  h2 {
    margin-bottom: 30px;
    font: 700 24px $font;
    color: #454545;
  }

  p {
    margin-bottom: 24px;
  }

  &-actions {
    display: flex;
    margin: 0 -7px;
    button {
      width: 50%;
      margin: 0 7px;
    }
  }

  &-background {
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255,255,255,0.92);
  }
}