@import '../../shared';
.wishlist {
  padding-bottom: 36px;
  &-head {
    padding: 24px;

    h2 {
      margin-bottom: 10px;
      font: 700 24px $font;
      // color: #3C3C3C;
    }
  }
  &-tooltip {
    position: relative;
    background: linear-gradient(180deg, #FCBE29 0%, #F8B32A 98.91%);
    box-shadow: inset 0 -1px 0 0 #CBCDCE;
    border-radius: 4px;
    padding: 14px;
    margin: 0 13px;
    text-align: center;

    &:after {
      content:'';
      position: absolute;
      width: 20px;
      height: 20px;
      left: calc(50% - 10px);
      bottom: -18px;
      background: url('../../assets/triangle.svg') no-repeat;
      background-size: 100%;
    }

    .tooltip-icon {
      width: 32px;
      height: 38px;
      margin: 0 auto 4px;
      background: url('../../assets/tooltip.svg');
      background-size: contain;
    }
  }
}

.wishlist-products-list {
  background: #fff;
  padding: 24px;
}

.wishlist-actions {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 24px;
  background: #fff;
  &:after {
    content:'';
    position: absolute;
    top: 16px;
    bottom: 24px;
    left: 50%;
    width: 1px;
    background: #DCDCDC;
  }

  .actions-item {
    display: block;
    width: 50%;
    padding-bottom: 12px;
    text-align: center;

    img {
      display: block;
      margin: 0 auto 8px;
    }
  }
}

.wishlist-info {
  padding: 16px 0;
  h2 {
    padding: 0 24px 16px;
    font:700 24px $font;
    // color: #3C3C3C;
  }

  .info-content {
    background: #fff;
    padding: 24px 24px;
  }

  .external-link {
    color: #00A4DF;
  }

  .internal-link {
    position: relative;
    display: block;
    padding-right: 70px;
    font: 700 14px $font;

    &:after {
      content:'';
      position: absolute;
      top:calc(50% - 7px);
      right: 30px;
      height: 14px; 
      width: 8px;
      background: url('../../assets/arrow.svg');
      background-size: contain;
    }
  }
}

.wishlist-placeholder {
  padding: 50px 30px;
  background: #fff;
  text-align: center;
  margin: 20px auto;
  font: 700 24px $font;
}