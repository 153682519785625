@import '../../shared';
.wishlist-product {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 0 31px;
  border-bottom: 1px solid #DCDCDC;

  &:last-child {
    border-bottom: none;
    padding-bottom: 10px;
  }

  .product-image {
    width: 20%;
    margin-right: 30px;
    img {
      width: 100%;
      max-width: 100px;
    }
  }

  .product-info {
    width: calc(80% - 70px);
    padding-right: 30px;
  }

  .product-actions {
    width: 40px;
  }
  
  .product-name {
    // color: #2E2E2E;
    font: 700 18px $font;
  }

  .product-amount {
    width: 33px;
    height: 33px;
    line-height: 33px;
    border-radius: 4px;
    text-align: center;
    border: 1px solid #DCDCDC;
  }

  .product-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    .action-item {
      display: block;
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.wishlist-amount {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: -24px;
  right: -24px;
  background: rgba(255, 255, 255, 0.3);

  &-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 250px;
    padding: 16px;
    background-color: #fff;
    box-shadow: 0 4px 12px 0 rgba(41,41,41,0.09);
    border-radius: 4px;
  }

  .btn {
    width: 84px;
    padding: 9px 16px;
  }

  .amount-spinner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 84px);
    margin-right: 24px;
    border: 1px solid #DCDCDC;
    border-radius: 18px;

    &-btn {
      padding: 6px 16px;
      font: 400 18px $font;
      color: #0079CA;
      text-align: center;
    }
  }

  input {
    width: 30px;
    border: none;
    box-shadow: none;
    background-color: transparent;
    text-align: center;
    font: 400 18px $font;
    color: #292929;
  }
}