@import '../../_shared';

.categories {
  padding-bottom: 70px;
}

.intro-banner {
  display: block;
  position: relative;
  padding: 0 30px 33.3%;
  background: rgba(255,188,13,0.4) url('../../assets/banner.png') no-repeat;
  background-size: contain;
  height: 0;
  font:400 4.8vw $font;
  color: #2E2E2E;

  h1 {
    font-weight:700;
    font-size: 8.5vw;
    padding-left: 15px;
  }

  .btn {
    position: absolute;
    right: 6%;
    bottom: 14%;
  }

  .banner-info {
    position: absolute;
    left: 24px;
    top:50%;
    transform: translateY(-50%);
  }
}

.category-item {
  font: 700 6.4vw $font;
  text-transform: capitalize;
  background-color: #fff;

  &:last-child {
    .category-link {
      border-bottom: none;
    }
  }

  .category-link {
    position: relative;
    border-bottom: 1px solid #DCDCDC;
    padding: 10px 20px;
    display: flex;
    align-items: center;

    &:after {
      content:'';
      position: absolute;
      top:calc(50% - 10px);
      right: 20px;
      width: 20px;
      height: 21px;
      background: url('../../assets/arrow.svg') no-repeat 100%;
    }
  }

  .category-img {
    width: 26%;
    height: 0;
    position: relative;
    padding-bottom: 26%;
    margin-right: 16px;

    img {
      position: absolute;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}