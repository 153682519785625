@import '../../_shared';

.input-spinner {
  margin-top: 16px;
  background-color: #fff;
  padding: 19px 20px 32px;
  text-align: center;

  .spinner-title {
    font: 400 18px $font;
    color: #2E2E2E;
  }

  .spinner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .spinner-trigger {
    width: 45px;
    height: 45px;
    margin:0 15px;
    border-radius: 50%;
    border: 1px solid #DCDCDC;
    font: 400 18px/45px $font;
    color: #757575;
    transition: color .2s ease-in-out;

    &:hover,
    &:active {
      color: #0079CA;
    }
  }

  input {
    border: none;
    box-shadow: none;
    background-color: transparent;
    text-align: center;
    color: #292929;
    font: 400 18px $font;
    width: 50px;
  }
}