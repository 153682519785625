@import '../../shared';

@keyframes bagScaling {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.2);
  }
  70% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.back-to-whishlist.btn-yellow {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 0;
  max-width: 100%;
  .whishlist-pocket {
    position: absolute;
    top: -15px;
    right: 25px;
    width: 36px;
    transform-origin: center bottom; 
    img {
      width: 100%
    }
    .amount {
      position: absolute;
      left: -8px;
      top: 4px;
      width: 17px;
      height: 17px;
      background: #DB0108;
      border-radius: 50%;
      font: 400 10px/17px $font;
      color: #fff;
      text-align: center;
    }

    &.animated {
      animation: bagScaling .7s;
      transform: scale(1);
    }
  }
}