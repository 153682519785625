@import '_shared';
@import '_fonts';

@import 'reset-css';

* {
  box-sizing: border-box;
  outline: none;
}

body, html {
  width: 100vw;
  min-height: 100vh;
  height: auto;
  overflow-x: hidden;
  user-select: none;
  -webkit-overflow-scrolling: none;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  color: #292929;
  font: 400 14px $font;
  background-color: #F9F9F9;
}

a {
  text-decoration: none;
  color: #292929;
}

.products-list {
  border-top: 1px solid #DCDCDC;
  display: flex;
  flex-wrap:wrap;
  justify-content: space-between;
  align-items: flex-end;
}

.btn {
  display: block;
  border: none;
  border-radius: 0;
  box-shadow: none;
  text-align: center;
}

.btn-white {
  padding: 3.5vw 5.3vw;
  background-color: #fff;
  border: none;
  border-radius: 2px;
  font: 400 3.7vw $font;
}

.btn-yellow {
  position: relative;
  width: 100%;
  max-width: 327px;
  margin: 0 auto;
  background-color: #FFBB02;
  font-size: 14px;
  border-radius: 4px;
  padding: 18px 20px;

  &.disabled {
    background: #DCDCDC;
    color: #ADADAD;
  }
}

.btn-transparent {
  background-color: #fff;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #ADADAD;
  padding: 18px 20px;
}

.loader {
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
  width: 15vw;
  height: 15vw;
  transform: translate(-50%, -50%);
  background: url('./assets/loader.svg');
  background-size: cover;
}

hr {
  height: 1px;
  margin: 24px 0;
  border: none;
  box-shadow: none;
  background: #DCDCDC;
}

.lazy-load-image-background.opacity.lazy-load-image-loaded {
  transition: opacity .2s;
}