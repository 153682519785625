@import '../../_shared';

.faq {
  .accordion-item {
    &:last-child {
      border-bottom: none;
    }
  }
}

.accordion-item {
  background-color: #fff;
  border-bottom: 1px solid #DCDCDC;

  &.open {
    .accordion-content {
      position: static;
      opacity: 1;
      visibility: visible;
    }
    .accordion-toggle {
      &:after {
        transform: rotate(-90deg);
      }
    }
  }
}

.accordion-content {
  position: fixed;
  left: -9999px;
  opacity: 0;
  visibility: hidden;
  transition: all .2s;
  padding: 24px;
}

.accordion-toggle {
  position: relative;
  padding: 24px 95px 24px 24px;
  min-height: 81px;
  display: flex;
  align-items: center;

  &:after {
    content:'';
    position: absolute;
    top:calc(50% - 8px);
    right: 23px;
    width: 16px;
    height: 16px;
    background: url('../../assets/arrow.svg') no-repeat center;
    background-size: contain;
    transform: rotate(90deg);
  }
}

.faq-title {
  padding: 0 24px 16px;
  font: 700 24px $font;
}