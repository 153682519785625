@import '../../_shared';

.tutorial-list {
  padding-top: 16px;
  margin-bottom: 32px;
  background-color: #fff;
  list-style: none;
  counter-reset: items-counter;
  li {
    counter-increment: items-counter;
    padding-bottom: 54px;
    &:before {
      content: counter(items-counter) "";
      display: block;
      width: 70px;
      height: 70px;
      background: #FFBC0D;
      border-radius: 50%;
      font:700 36px/70px $font;
      text-align: center;
      color: #fff;
    }

    &:first-child {
      .step-image {
        padding-bottom: 60%;
      }
    }
    &:nth-child(2) {
      .step-image {
        padding-bottom: 105%;
      }
    }
    &:last-child {
      .step-image {
        padding-bottom: 90%;
      }
    }
  }
}

.tutorial-step {
  padding: 0 24px;
  margin-top: -20px;

  .step-title {
    margin-bottom: 24px;
    font: 700 32px/1 $font;
    color: #353535;
  }

  .step-info {
    margin-bottom: 24px;
  }

  .step-image {
    position: relative;
    height: 0;

    img {
      position: absolute;
      top:50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 100%;
    }
  }
}

.tutorial {
  padding-bottom: 100px;
  .proceed-btn {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 8px 24px;
    border-top: 1px solid #DCDCDC;
    background-color: #fff;
  }
}

.faq {
  margin-bottom: 50px;

  a {
    color: #00A4DF;
  }
}