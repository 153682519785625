@import '../../_shared';
.product-detail {
  padding-bottom: 100px;
  &-info {
    padding: 34px 24px;
    background-color: #fff;
  }
  h2 {
    font: 700 32px $font;
  }
  .read-more {
    color: #0079CA;
  }
  &-description {
    margin-bottom: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; 
    overflow: hidden;
    line-height: 1.4;

    &.full {
      -webkit-line-clamp: inherit;
    }
  }
  &-img {
    display: block;
    position: relative;
    height: 0;
    padding-bottom: 100%;
    img{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
    }
  }
}

.product-options {
  padding-top: 16px;
  h3 {
    padding: 0 24px 16px;
    font: 700 24px $font;
  }

  .accordion-item {
    border: none;

    &.open {
      position: relative;
      z-index: 1;
      box-shadow: 0 4px 12px 0 rgba(41,41,41,0.09);
      .accordion-toggle {
        &:before {
          border-color: #F9F9F9;
        }
      }

      &:last-child {
        .accordion-toggle {
          &:before {
            border-color: #F9F9F9;
          }
        }
      }
    }
  }

  .accordion-toggle {
    padding: 24px;
    &:before {
      content:'';
      position: absolute;
      left: 24px;
      right: 24px;
      bottom: 0;
      border-bottom: 1px solid #DCDCDC;
    }
  }

  .accordion-content {
    padding: 5px 24px;
  }

  .option-title {
    display: flex;
    width: 100%;
    align-items: center;
    font: 700 14px $font;
    color: #2E2E2E;

    picture {
      width: 23%;
      margin-right: 16px;

      img {
        width: 100%;
      }
    }

    .option-name {
      width: calc(75% - 16px);
    }
  }

  .option-note {
    font-weight: 400;
  }

  .option-item {
    padding: 26px 0;
    border-bottom: 1px solid #F9F9F9;

    &:last-child {
      border: none;
    }
  }

  input[type='radio'] {
    position: absolute;
    left: -9999px;
    visibility: hidden;
    opacity: 0;

    + label {
      display: block;
      position: relative;
      padding-left: 40px;

      &:before {
        content:'';
        position: absolute;
        left: 0;
        top: calc(50% - 13px);
        width: 26px;
        height: 26px;
        border: 1px solid #DCDCDC;
        border-radius: 50%;
      }

      &:after {
        content:'';
        position: absolute;
        left: 5px;
        top: calc(50% - 8px);
        width: 18px;
        height: 18px;
        background-color: #FFBC0D;
        border-radius: 50%;
        transform: scale(0);
        transition: transform .2s;
      }
    }

    &:checked {
      + label {
        font-weight: 700;
        &:after {
          transform: scale(1);
        }
      }
    }
  }

  .option-category {
    position: relative;
    &:before {
      content:'';
      position: absolute;
      z-index: 2;
      left: 0;
      top: 0;
      width: 4px;
      height: 0;
      background: #FFBC0D;
      transition: all .2s .2s;
    }

    &.error {
      &:before {
        height: 100%;
      }
    }

    &.animated {
      .accordion-item {
        animation: shake .2s;
      }
    }

    &:last-child {
      .accordion-toggle {
        &:before {
          border-color: transparent;
        }
      }
    }
  }
}


@keyframes fadeOut {
  0% {
    opacity: 100%;
  }

  90% {
    opacity: 0.4;
  }
}

@keyframes productTransition {
  0% {
    transform: translateY(0) scale(1);
  }
  100% {
    transform: translateY(calc(70vh - 75px - 50%)) scale(0.5);
  }
}

@keyframes shake {
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  30% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  60% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}

.fake-photo {
  position: fixed; 
  opacity: 0;
  width: 70px;
  top: 30%;
  right: 10px;
  &.animated {
    opacity: 1;
    animation: productTransition .4s linear;
    transform: translateY(calc(90vh - 75px - 50%)) scale(0.5);
  }
}