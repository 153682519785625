.category-description {
  padding: 24px;
  color: #757575;
  h2 {
    margin-bottom: 10px;
    color: #3C3C3C;
    font-size: 22px;
    font-weight: 700;
    text-transform: capitalize;
  }
}