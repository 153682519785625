@font-face {
  font-family: 'Speedee';
  font-weight: 700;
  src: url("assets/fonts/Speedee_W_Bd.woff") format("woff");
}

@font-face {
  font-family: 'Speedee';
  font-weight: 400;
  src: url("assets/fonts/Speedee_W_Rg.woff") format("woff");
}